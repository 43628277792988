<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:45
-->
<template>
	<div class="baseDetail">
		<common-title :title="'共建基地详情'" />
		<div class="baseDetail-content">
			<div class="common-table">
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">共建基地ID</span>
						<el-input v-model="baseDetail.id" placeholder="请输入" class="common-table-val" />
					</div>
					<div class="common-table-col">
						<span class="common-table-label">当前状态</span>
						<span
							class="common-table-text">{{baseDetail.state == 1? "开启":baseDetail.state == 0? "关闭":""}}</span>
						<el-button @click="openBase" v-if="baseDetail.state == 0&&visitType != 'baseAll'" class="common-table-btn"
							type="primary">开启</el-button>
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">基地名称</span>
						<el-input v-model="baseDetail.name" placeholder="请输入" class="common-table-val" />
					</div>
					<div class="common-table-col">
						<span class="common-table-label">基地所在组织</span>
						<el-input v-model="baseDetail.organization" placeholder="请输入" class="common-table-val" />
					</div>
				</div>
				<div v-if="visitType == 'baseAll'" class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">总工时</span>
						<el-input v-model="baseDetail.totalWorkingHours" placeholder="请输入" class="common-table-val" />
					</div>
					<div class="common-table-col">
						<span class="common-table-label">活动次数</span>
						<el-input v-model="baseDetail.activityNum" placeholder="请输入" class="common-table-val" />
					</div>
				</div>
				<div v-if="visitType == 'baseAll'" class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">活动人次</span>
						<el-input v-model="baseDetail.activityPeoNum" placeholder="请输入" class="common-table-val" />
					</div>
					<div class="common-table-col">
						<span class="common-table-label">创建时间</span>
						<el-input v-model="baseDetail.createTime" placeholder="请输入" class="common-table-val" />
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">活动详情</span>
						<quill-editor :options="editorOption" class="common-table-val" :style="{'height': '676px'}" v-model="baseDetail.details"
							@focus="editorFocus($event)">
						</quill-editor>
						<!-- <el-input type="textarea" v-model="baseDetail.plan" placeholder="请输入"
							class="common-table-val" /> -->
					</div>
				</div>
				<div class="common-table-row" v-if="baseDetail.contactPerson">
					<div class="common-table-col">
						<span class="common-table-label">联系人</span>
						<el-input v-model="baseDetail.contactPerson" placeholder="请输入" class="common-table-val" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		detailMyBase,
		disableBase,
	} from "@/api/activity";
	export default {
		data() {
			return {
				baseDetail: {},
				baseId: "",
				visitType: "",
				editorOption: {
					placeholder: "暂无内容......",
				}
			};
		},
		mounted() {
			this.visitType = this.$route.query.visitType
			this.baseId = this.$route.query.baseId
			this.getMyBaseDetail()
		},
		methods: {
			editorFocus(e) {
				e.enable(false);
			},
			// 共建基地详情
			getMyBaseDetail() {
				detailMyBase(this.baseId, {
					id: this.baseId
				}).then(res => {
					if (res.code == 0) {
						this.baseDetail = res.data
					}
				})
			},
			openBase() {
				this.$confirm('确定开启此共建基地吗?', '开启共建基地', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let params = {
						id: this.baseDetail.id,
						state: 1
					}
					disableBase(this.baseDetail.id, params).then((res) => {
						if (res.code == 0) {
							this.baseDetail.state = 1
							this.$message({
								type: 'success',
								message: '开启成功!'
							});
							this.$forceUpdate()
						}
					})
				}).catch(() => {});
			},
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
	}

	/deep/.el-textarea__inner {
		height: 676px !important;
		resize: none;
		border: 0;
	}

	.baseDetail {
		.baseDetail-content {
			padding: 20px 20px;

			.common-table-col {
				position: relative;

				.common-table-val {
					// overflow: hidden; //超出的文本隐藏
					// text-overflow: ellipsis; //溢出用省略号显示
					// white-space: nowrap; //溢出则不换行
				}

				.common-table-btn {
					width: 60px;
					height: 28px;
					position: absolute;
					top: 4px;
					right: 11px;
				}
			}
		}
	}
</style>
